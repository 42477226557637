import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const AddMessage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    from_email: 'admin@rapsodyapp.rs',
    to_email: '',
    subject: '',
    content: '',
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      !values.from_email ||
      !values.to_email ||
      !values.subject ||
      !values.content ||
      values.from_email === values.to_email
    ) {
      setOpenToast(true);
      setToastmessage('Please fill all details');
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/message`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(values),
      });
      const data = await res.json();
      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/message');
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="ADD FEEDBACK QUESTION" subtitle="Add a New Feedback Question" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="From"
            onChange={handleChange}
            name="from_email"
            value={values.from_email}
            required
            disabled
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#fff',
              },
            }}
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="To"
            onChange={handleChange}
            name="to_email"
            value={values.to_email}
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Subject"
            onChange={handleChange}
            name="subject"
            value={values.subject}
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            multiline
            rows={15}
            label="Message"
            onChange={handleChange}
            name="content"
            value={values.content}
            required
          />

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Send
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AddMessage;
