import { Box, Button, CircularProgress, Divider, IconButton, Snackbar, Typography } from '@mui/material';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const UserFeedbacksDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');

  const [data, setData] = useState({
    user_id: '',
    name: '',
    surname: '',
    email: '',
    feedback: [],
  });

  useEffect(() => {
    const getFeedbackDetails = async () => {
      setLoading(true);
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/userfeedback/${id}`, { headers: { token } });
      const data = await res.json();
      if (data.success) {
        setData(data.data);
      }
      setLoading(false);
    };
    getFeedbackDetails();
  }, []);

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="FEEDBACK RESPONSE" subtitle="View Feedback response of Passenger" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <Box display="grid" gap="10px" mb="2rem">
        <Typography variant="h3" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
          Passenger Details
        </Typography>

        <Box display="flex" gap="10px" flexWrap={'wrap'}>
          <Box
            flex={1}
            border={1}
            bgcolor={colors.primary[400]}
            borderColor={colors.primary[300]}
            borderRadius="8px"
            p="10px"
          >
            <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
              <PersonIcon /> Name
            </Typography>
            <Typography variant="h4" sx={{ color: colors.grey[100] }}>
              {data.name} {data.surname}
            </Typography>
          </Box>

          <Box
            flex={1}
            border={1}
            bgcolor={colors.primary[400]}
            borderColor={colors.primary[300]}
            borderRadius="8px"
            p="10px"
          >
            <Typography variant="h5" color={colors.greenAccent[500]} display="flex" alignItems="center" gap="5px">
              <MailOutlineIcon /> Email
            </Typography>
            <Typography variant="h4" sx={{ color: colors.grey[100] }}>
              {data.email}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="grid" gap="10px" mb="2rem" bgcolor={colors.primary[400]} p={2} borderRadius={3}>
        <Typography variant="h3" fontWeight={'bold'} color={colors.grey[100]} mb="5px">
          Feedback
        </Typography>

        <Divider />

        <Box display="grid" gap="1.2rem">
          {data.feedback.length > 0 ? (
            data.feedback.map((feedback) => (
              <Box flex={1} borderColor={colors.primary[300]} borderRadius="8px" key={feedback.ques_id}>
                <Typography variant="h5" color="white">
                  <span style={{ fontWeight: 'bold' }}>Question:</span> {feedback.ques_name}
                </Typography>
                <Typography variant="h5" sx={{ color: colors.grey[200] }}>
                  <span style={{ fontWeight: 'bold' }}>Answer:</span> {feedback.ans}
                  {/* <span style={{ fontWeight: 'bold' }}>Answer:</span> {feedback.ans ? feedback.ans : 'No Answer'} */}
                </Typography>
              </Box>
            ))
          ) : (
            <Box flex={1} borderColor={colors.primary[300]} borderRadius="8px">
              <Typography variant="h5" color={colors.grey[400]} fontStyle="italic">
                No Feedback
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default UserFeedbacksDetails;
