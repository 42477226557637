import { Box, Button, Grid, IconButton, Snackbar, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const MessageDetails = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');

  const token = localStorage.getItem('token');

  const [values, setValues] = useState({
    from_email: '',
    to_email: '',
    subject: '',
    content: '',
  });

  useEffect(() => {
    const getDetails = async () => {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/message/${id}`, {
        headers: { token },
      });
      const data = await res.json();
      if (data.success) {
        setValues({
          from_email: data.data.from_email,
          from_name: data.data.from_name,
          to_email: data.data.to_email,
          to_name: data.data.to_name,
          subject: data.data.subject,
          content: data.data.content,
        });
      }
    };
    getDetails();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="Message" subtitle="View Message" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        style={{
          display: 'grid',
          gap: '10px',
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="From"
          onChange={handleChange}
          name="from_email"
          value={values.from_email}
          required
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#fff',
            },
          }}
        />

        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="To"
          onChange={handleChange}
          name="to_email"
          value={values.to_email}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#fff',
            },
          }}
        />

        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Subject"
          onChange={handleChange}
          name="subject"
          value={values.subject}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#fff',
            },
          }}
        />

        <TextField
          fullWidth
          variant="filled"
          type="text"
          multiline
          minRows={15}
          label="Message"
          onChange={handleChange}
          name="content"
          value={values.content}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#fff',
            },
          }}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default MessageDetails;
